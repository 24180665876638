<template>
  <div class="row no-wrap items-baseline gap-sm text-neutral-7">
    <template v-for="(crumb, idx) in crumbs" :key="idx">
      <breadcrumb-el :crumb="crumb" />
      <span v-if="idx < crumbs.length - 1">/</span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useRouteParams } from "@/composables/useRouteParams";
import { INBOX_VIEWS } from "@/config/inboxViews";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import BreadcrumbEl, { type Breadcrumb } from "./BreadcrumbEl.vue";

const { inboxView, inquiryId } = useRouteParams();
const { inbox } = storeToRefs(useCurrentInboxStore());
const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { t } = useI18n();
const { currentRoute } = useRouter();

const crumbs = computed<Breadcrumb[]>(() => {
  const crumbs: Breadcrumb[] = [];

  if (currentRoute.value.name == "quotations") {
    crumbs.push({
      label: t("quotationsPage.title"),
    });
    return crumbs;
  }

  if (currentRoute.value.name?.toString().startsWith("supplier-rfqs")) {
    crumbs.push({
      label: t("supplierRfqsPage.title"),
    });

    return crumbs;
  }

  if (currentRoute.value.name == "products") {
    crumbs.push({
      label: t("Products"),
    });
    return crumbs;
  }

  if (currentRoute.value.name == "customers") {
    crumbs.push({
      label: t("Customers"),
    });
    return crumbs;
  }

  if (currentRoute.value.name == "suppliers") {
    crumbs.push({
      label: t("Suppliers"),
    });
    return crumbs;
  }

  if (currentRoute.value.name == "account-settings") {
    crumbs.push({
      label: t("Account settings"),
    });
    return crumbs;
  }

  if (currentRoute.value.name == "organization-settings") {
    crumbs.push({
      label: t("Organization settings"),
    });
    return crumbs;
  }

  if (currentRoute.value.name?.toString().includes("notifications")) {
    crumbs.push({
      label: t("notificationsPage.label"),
    });
    return crumbs;
  }

  if (inbox.value !== null && inboxView.value != undefined) {
    crumbs.push(buildInboxViewsBreadcrumbs());
  }

  if (!isNaN(inquiryId.value)) {
    crumbs.push({ label: inquiry.value?.shortCode ?? "" });
    crumbs.push(buildInquiryBreadcrumbs());
  }

  return crumbs;
});

function buildInboxViewsBreadcrumbs() {
  const inboxViewsMenu = INBOX_VIEWS.map((view) => ({
    label: t(`inboxView_${view}`),
    to: { name: "inbox", params: { inboxView: view } },
  }));

  return {
    label: `${inbox.value?.name} - ${t(`inboxView_${inboxView.value}`)}`,
    to:
      currentRoute.value.name === "inbox"
        ? undefined
        : { name: "inbox", params: { inboxView: inboxView.value } },
    menu: currentRoute.value.name === "inbox" ? inboxViewsMenu : undefined,
  };
}

const { organization } = storeToRefs(useCurrentOrganizationStore());

function buildInquiryBreadcrumbs() {
  const inquiryPages = [
    { label: t("inquiryPage.title"), to: { name: "inquiry" } },
    {
      label: t("inquiryPositionsPage.title"),
      to: { name: "inquiry-positions" },
    },
  ];

  if (organization.value?.useSupplierData) {
    inquiryPages.push({
      label: t("supplierRfqsPage.title"),
      to: { name: "inquiry-supplier-rfqs" },
    });
  }

  return {
    label: getInquiryPageTitle(),
    menu: inquiryPages,
  };
}

function getInquiryPageTitle() {
  if (currentRoute.value.name === "inquiry-positions")
    return t("inquiryPositionsPage.title");

  if (currentRoute.value.name?.toString().startsWith("inquiry-supplier-rfqs"))
    return t("supplierRfqsPage.title");

  return t("inquiryPage.title");
}
</script>

<style lang="scss">
.header-bar-breadcrumbs {
  overflow-x: hidden;
  flex-shrink: 1;

  > div {
    flex-wrap: nowrap;
    > div {
      white-space: nowrap;
    }
  }
}
</style>
