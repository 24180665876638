<template>
  <q-input ref="inputRef" v-model="formattedValue" />
</template>

<script setup lang="ts">
import { DEFAULT_CURRENCY } from "@/config/defaults";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";
import { useI18n } from "vue-i18n";

const props = withDefaults(
  defineProps<{
    modelValue: number | null;
    precision?: number;
  }>(),
  {
    precision: 2,
  }
);

const emit = defineEmits<{
  "update:modelValue": [value: number | null];
  change: [value: number | null];
}>();

const { locale } = useI18n();
const { organization } = storeToRefs(useCurrentOrganizationStore());

const currency = computed(
  () => organization.value?.details.currency || DEFAULT_CURRENCY
);

const options = computed(() => ({
  currency: currency.value,
  locale: locale.value,
  precision: props.precision,
}));

const { inputRef, formattedValue, numberValue, setValue, setOptions } =
  useCurrencyInput(options.value);

watch(options, setOptions);

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue !== numberValue.value) {
      setValue(newValue);
    }
  }
);

watch(numberValue, (newValue) => {
  emit("update:modelValue", newValue);
});
</script>

<style scoped lang="scss">
.price-input-container {
  width: 100px;
}

.price-input {
  width: 100%;
}
</style>
